import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import SettingsContext from "src/context/SettingsContext";
import Footer from "../DashboardLayout/Footer";
import LoginHeader from "./LoginHeader";

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: "hidden",
    position: "relative",
    height: "100vh",
    // minHeight: "calc(100vh - 415px)",
  },

  root: {
    background: "url('images/lightbackground.png')",
    overflow: "hidden",
    position: "relative",
    height: "100vh",
  },
  root1: {
    // backgroundColor: "#000",
    overflow: "hidden",
    position: "relative",
    background: "url('images/darkbackground.png')",
    height: "100vh",
  },
  MainLayout: {
    height: "calc(100vh - 75px)",
    // height: "100%",
    position: "relative",
    overflowY: "auto",
  },
  contentContainer1: {
    height: "calc(100vh - 75px)",
    // height: "100%",
    position: "relative",
  },

 
}));

const MainLayout = ({ children }) => {
  const classes = useStyles();
  const history = useHistory();
  const themeSeeting = useContext(SettingsContext);
  return (
    <div
      className={
        themeSeeting.settings.theme === "DARK"
          ? `${classes.root1}`
          : `${classes.root}`
      }
    >
      <LoginHeader />
      <div className={classes.contentContainer1}>
        <div className={classes.MainLayout}>{children}</div>
       
      </div>
      <Footer />
    </div>
  );
};

export default MainLayout;
