import React, { lazy } from "react";
import { Redirect } from "react-router-dom";
import LoginLayout from "src/layouts/LoginLayout";
import DashboardLayout from "src/layouts/DashboardLayout";

export const routes = [
  {
    exact: true,
    path: "/",
    layout: LoginLayout,
    component: lazy(() => import("src/views/pages/Auth/Login")),
  },

  {
    exact: true,
    path: "/kyc-list",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/KycManagement/KycList")
    ),
  },

  {
    exact: true,
    guard: true,
    path: "/sniper-dashboard",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/SniperDashboard/DashboardHome")
    ),
  },

  {
    exact: true,
    guard: true,
    path: "/sniper-user-management",
    layout: DashboardLayout,
    component: lazy(() =>
      import(
        "src/views/pages/Dashboard/SniperUserManagement/SniperUserManagement"
      )
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/sniper-subscription-management",
    layout: DashboardLayout,
    component: lazy(() =>
      import(
        "src/views/pages/Dashboard/SniperSubscriptionManagement/SniperSubscriptionManagement"
      )
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/sniper-change-password",
    layout: DashboardLayout,
    component: lazy(() =>
      import(
        "src/views/pages/Dashboard/SniperChangePassword/SniperChangePassword"
      )
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/sniper-otp-verification",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/SniperChangePassword/otpVerification")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/change-password",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/SniperChangePassword/ChangePasswordOTP")
    ),
  },

  {
    exact: true,
    guard: true,
    path: "/view-sniper-user",
    layout: DashboardLayout,
    component: lazy(() =>
      import(
        "src/views/pages/Dashboard/SniperSubscriptionManagement/ViewSniper"
      )
    ),
  },

  {
    exact: true,
    guard: true,
    path: "/edit-sniper-user",
    layout: DashboardLayout,
    component: lazy(() =>
      import(
        "src/views/pages/Dashboard/SniperSubscriptionManagement/EditSniper"
      )
    ),
  },

  {
    exact: true,
    guard: true,
    path: "/subscription-list",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/SubscriptionManage/Subscription")
    ),
  },

  {
    exact: true,
    guard: true,
    path: "/working-plan",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/WorkingPlan/WorkingPlan")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/view-working-plan",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/WorkingPlan/ViewTree")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/view-user-tree",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/UserManagement/UserTreeList")
    ),
  },

  {
    exact: true,
    guard: true,
    path: "/trading-bonus",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/TradingBonus/TradingBonus")
    ),
  },


  {
    exact: true,
    guard: true,
    path: "/pre-launching",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/PreLaunching/PreLaunching")
    ),
  },

  {
    exact: true,
    guard: true,
    path: "/pre-launching-configuration",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/PreLaunchingConfiguration/PreLaunchingConfiguration")
    ),
  },

  
  {
    exact: true,
    guard: true,
    path: "/salary-income",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/SalaryIncome/SalaryIncome")
    ),
  },

  {
    exact: true,
    guard: true,
    path: "/salary-configuration",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/SalaryConfiguration/ConfigureSalaryIncome")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/view-salaryConfigure",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/SalaryConfiguration/ViewConfigure")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/edit-salaryConfigure",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/SalaryConfiguration/editSalaryConfigure")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/reward-configuration",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/RewardConfiguration/ConfigureReward")
    ),
  },

  {
    exact: true,
    guard: true,
    path: "/view-rewardconfiguration",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/RewardConfiguration/ViewRewardConfigure")
    ),
  },

  {
    exact: true,
    guard: true,
    path: "/edit-rewardconfiguration",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/RewardConfiguration/EditRewardConfigure")
    ),
  },

  {
    exact: true,
    guard: true,
    path: "/reward-income",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/Reward Bonus/RewardIncome")
    ),
  },

  {
    exact: true,
    path: "/dashboard",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/DashboardManagement/Dashboard")
    ),
  },
  {
    exact: true,
    path: "/bot-settings",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/Botsettings/BotSettings")
    ),
  },

  {
    exact: true,
    path: "/view-kyc",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/KycManagement/ViewKyc")
    ),
  },
  {
    exact: true,
    path: "/user-list",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/UserManagement/UserList")
    ),
  },
  {
    exact: true,
    path: "/view-user",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/UserManagement/ViewUser")
    ),
  },
  {
    exact: true,
    path: "/forget",
    layout: LoginLayout,
    component: lazy(() => import("src/views/pages/Auth/Forget")),
  },
  {
    exact: true,
    path: "/change-password",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Auth/ChangePassword")),
  },
  {
    exact: true,
    path: "/sniper-view-user",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/SniperUserManagement/ViewSniperUser")
    ),
  },

  {
    exact: true,
    path: "/profile",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Profile")),
  },
  {
    exact: true,
    path: "/edit-profile",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/EditProfile")),
  },
  {
    exact: true,
    path: "/admin-wallet-setting",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/AdminWallet/AdminWallet")),
  },
  {
    exact: true,
    path: "/admin-transaction-history",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/AdminTransactionHistory/Transactionhistory")),
  },

  {
    exact: true,
    path: "/verify-otp",
    layout: LoginLayout,
    component: lazy(() => import("src/views/pages/Auth/Verifyotp")),
  },
  {
    exact: true,
    path: "/otp-verification",
    layout: LoginLayout,
    component: lazy(() => import("src/views/pages/Auth/OtpVerification")),
  },
  {
    exact: true,
    path: "/reset-password",
    layout: LoginLayout,
    component: lazy(() => import("src/views/pages/Auth/ResetPassword")),
  },
  {
    exact: true,
    path: "/view-subscription",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/SubscriptionManage/ViewSubscription")
    ),
  },
  {
    exact: true,
    // guard: true,
    path: "/subscription-management",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/SubscriptionManage/Subscription")
    ),
  },
  {
    exact: true,
    // guard: true,
    path: "/add-subscription",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/SubscriptionManage/AddSubscibe")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/threshold-list",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/Threshold/Threshold")
    ),
  },

  {
    exact: true,
    guard: true,
    path: "/view-threshold",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/Threshold/ViewThreshold")
    ),
  },

  {
    exact: true,
    guard: true,
    path: "/edit-threshold",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/Threshold/EditThreshold")
    ),
  },

  {
    exact: true,
    guard: true,
    path: "/affiliation-management",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/AffiliationManagement/Affiliation")
    ),
  },


  {
    exact: true,
    path: "/404",
    component: lazy(() => import("src/views/errors/NotFound")),
  },
  {
    component: () => <Redirect to="/404" />,
  },
];
