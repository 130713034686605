import React from "react";
import { makeStyles } from "@material-ui/core";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  loginHeader: {
    background: "#0D0D0D",
    padding: "7px 30px",
  },
  logo: {
    cursor: "pointer",
  },
}));
const LoginHeader = () => {
  const classes = useStyles();
  const history = useHistory();
  return (
    <div className={classes.loginHeader}>
      <img
        src="/images/logo.svg"
        alt="LoginHeader"
        className={classes.logo}
        onClick={() => history.push("/")}
      />
    </div>
  );
};

export default LoginHeader;
