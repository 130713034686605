import {
    AppBar,
    makeStyles,
    Box,
    Container,
    Typography,
  } from "@material-ui/core";
  
  import React, { useState, useEffect, useContext } from "react";
  import { Link, useHistory, useLocation } from "react-router-dom";
  
  import SettingsContext from "src/context/SettingsContext";
  import { BsFillCaretDownFill } from "react-icons/bs";
  
  const useStyles = makeStyles((theme) => ({
    backgroundBox: {
      border: "none",
      bottom: "0",
      //  background:'pink'
    },
    appBoxAuth: {
      position: "relative",
      bottom: "19px",
      zIndex: "999",
    },
    appBox: {
      position: "fixed",
      bottom: "0",
      width: "100%",
      zIndex: "1201",
    },
    appBar: {
      background:"linear-gradient(90deg, #0B0E11 0%, #FCD535 100%)",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: "30px",
      position:'fixed',
      top: "auto",
      bottom: 0,
      width:'100%',
      
    },
    TextContainer: {
      textAlign: "center",
    },
    footerText: {
      [theme.breakpoints.down("xs")]: {
        fontSize: "11px !important",
      },
    },
  }));
  
  export default function Footer() {
    const classes = useStyles();
    const [anchorEl1, setAnchorEl1] = React.useState(null);
  
    const themeSeeting = useContext(SettingsContext);
    const changeTheme = (type) => {
      themeSeeting.saveSettings({
        theme: type,
      });
    };
    const handleClose4 = () => {
      setAnchorEl1(null);
    };
    const handleClick1 = (event) => {
      setAnchorEl1(event.currentTarget);
    };
    const { backgroundBox } = useStyles();
    const history = useHistory();
    const [state, setState] = useState({
      mobileView: false,
      drawerOpen: false,
    });
    const { mobileView, drawerOpen } = state;
    useEffect(() => {
      const setResponsiveness = () => {
        return window.innerWidth < 1220
          ? setState((prevState) => ({ ...prevState, mobileView: true }))
          : setState((prevState) => ({ ...prevState, mobileView: false }));
      };
  
      setResponsiveness();
  
      window.addEventListener("resize", () => setResponsiveness());
    }, []);
  
    return (
      <>
        <Box
          className={
            window.location.pathname === "/" ||
            window.location.pathname === "/forget" ||
            window.location.pathname === "/verify-otp" ||
            window.location.pathname === "/reset-password"||
            window.location.pathname === "/term-and-condition"||
            window.location.pathname === "/privacy-policy"||
            window.location.pathname === "/user-service"
              ? classes.appBoxAuth
              : classes.appBox
          }
        >
          <Box
            // position={history.location.pathname !== "/" ? "fixed" : "absolute"}
            className={classes.appBar}
            style={{ position: "fixed !important" }}
          >
            <Container maxWidth="lg" className={classes.TextContainer}>
              <Typography
                variant="h6"
                color="primary"
                className={classes.footerText}
              >
                     Admin panel Developed By Mobiloitte Technologies
              </Typography>
            </Container>
          </Box>
        </Box>
      </>
    );
  }